import {useCallback, useEffect, useState} from "react";
import {Container, Navbar} from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import {Link} from "react-router-dom";
import {isLoggedIn} from "../api/apiConfig";
import useLogout from "../hooks/useLogout";
// import useScrollEventListener from "../hooks/useScrollEvenListener";
import {ButtonFancy} from "../basic/ButtonFancy";


export default function Header(props: {
    links: { to: string, title: string, end?: boolean }[],
    webpage?: boolean
}): JSX.Element {
    // const collapsHeader = useScrollEventListener({scrollY: 10}).triggering
    const [toggle, setToggle] = useState(false)
    const logout = useLogout()

    const navLinkClassName = useCallback((props: { isActive: boolean, isPending: boolean }) => {
        const baseClass = ["nav-link", "scrollto", "px-3"]
        props.isActive && baseClass.push("active")
        return baseClass.join(" ")
    }, [])

    return <header id="header" className="header fixed-top header-scrolled w-100" style={{backgroundColor:'#21004b'}}>
        <Container fluid className="container-xl">
            <Navbar collapseOnSelect expand="lg" className="w-100 d-flex align-items-center justify-content-between">
                <Link to="/" className="logo d-flex align-items-center fw-bold fs-4">
                    <img className="me-1" src="/assets/img/fluff-gold.webp" alt=""/>
                    <img src="/assets/img/logo-text-line.webp" alt="Fairy Tale Fables" className="h-100"/>
                </Link>
                <NavbarToggle className="bg-white" onClick={() => setToggle(!toggle)}/>
                <NavbarCollapse style={{flexGrow: "unset"}}>
                    {
                        props.links.map(link => <Link to={link.to} className={navLinkClassName + " "}
                                                      key={link.to}>{link.title}</Link>)
                    }
                    {
                        isLoggedIn() ?
                            <>
                                <Link className="nav-link px-3"
                                      to={props.webpage ? "/app" : "/"}>{props.webpage ? "To Profile" : "To Website"}</Link>
                                <ButtonFancy bg="red" onClick={logout} className="ms-4">Logout</ButtonFancy></>
                            : <>
                                <Link className="nav-link" to="/app/login">Login</Link>
                                <Link to="/app/register"><ButtonFancy bg="blue">Register</ButtonFancy></Link>
                            </>
                    }
                </NavbarCollapse>
                {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
            </Navbar>
        </Container>
        <div className="w-100 h-100 position-relative">
            <CookieHeader/>
        </div>
    </header>
}

function CookieHeader(props: {}) {
    const [cookie, cookieSet] = useState(false)
    const [analytics, analyticsSet] = useState(true)

    useEffect(() => {
        if (localStorage.getItem("cookie")) cookieSet(true)
    }, []);

    const cookieOk = useCallback(() => {
        localStorage.setItem("cookie", analytics ? "1" : "0")
        cookieSet(true)
        window.location.reload();
    }, [analytics]);

    if (cookie) return <></>;

    return <div className="bg-fancy position-absolute end-0 p-3" style={{maxWidth: "350px", top: "100%"}}>
        <h5>This website uses cookies.</h5>
        <h6>We use cookies to save login info and use Google Analytics to analyze visitor numbers and behaviour on the
            website.</h6>
        <div className="container mb-2">
            <div className="row">
                <div className="col">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" disabled checked/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Necessary</label>
                    </div>
                </div>
                <div className="col">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" checked={analytics}
                               onChange={() => analyticsSet(!analytics)}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Analytics</label>
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <ButtonFancy bg="green" className="m-auto" onClick={cookieOk}>Save Choice</ButtonFancy>
        </div>
    </div>
}