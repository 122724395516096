import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {Card, Form} from 'react-bootstrap';
import {EnvelopeAt, KeyFill} from "react-bootstrap-icons";
import {authLogin, authLoginErrors, authLoginSteam, UserLoginInfo} from "./AuthenticationService";
import {ErrorObject, getError, isLoggedIn, setToken} from "../api/apiConfig";
import {AxiosError, AxiosResponse} from "axios";
import {MessageDisplay, MessageState} from "../components/Message";
import InputControlled from "../components/InputControlled";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ButtonFancy} from "../basic/ButtonFancy";
import {gameUserInfoErrors} from "../game/GameService";
import {queryParamGet} from "../api/util";

export default function LoginPage() {
    const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [loginInfo, loginInfoSet] = useState<UserLoginInfo>({email: "", password: ""});

    const navigate = useNavigate();
    const location = useLocation();

    const redirectCallback = useCallback(() => {
        let navigateTo = "/app";
        if (location.search.length > 1) {
            const query = location.search;

            const redirect = queryParamGet(query, "redirect");
            if(redirect) navigateTo = redirect;

            const focus = queryParamGet(query, "focus");
            if(focus) navigateTo += "#"+focus;
        }

        navigate(navigateTo);
    }, [location.search, navigate]);

    const onLogin = useCallback((event: FormEvent) => {
        event.preventDefault();
        if(!loginInfo) return;

        submittingSet(true);
        messageSet(undefined);
        authLogin(loginInfo).then((response: AxiosResponse<string>) => {
            //messageSet({type: "success", message: "Du wurdest erfolgreich angemeldet."});
            setToken(response.data);
            redirectCallback();
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, authLoginErrors)});
        }).finally(() => submittingSet(false));
    }, [loginInfo, redirectCallback]);

    useEffect(() => {
        if(isLoggedIn()) {
            redirectCallback();
        }
        else {
            console.log(window.location.search);
            const search = window.location.search;
            if(!search.includes("openid.ns")) return;
            authLoginSteam(search).then((response: AxiosResponse<string>) => {
                setToken(response.data);
                navigate("/app");
            }, (error: AxiosError<ErrorObject>) => {
                messageSet({type: "danger", message: getError(error.response?.data, gameUserInfoErrors)});
            });
        }
    }, [navigate, redirectCallback])

    return (
        <div className="container d-flex align-items-center justify-content-center" style={{height: "100vh"}}>
            <Card style={{maxWidth: "600px"}} className="w-100 bg-fancy">
                <Form onSubmit={onLogin} className='bg-fancy-offset'>
                    <Card.Body>
                        <Card.Title className="text-center fw-bold">Login</Card.Title>
                        <MessageDisplay message={message}/>
                        <Form.Group className="my-3">
                            <Form.Label className="text-left"><EnvelopeAt /> Email address</Form.Label>
                            <InputControlled type="text" name="username" autoComplete="username" value={loginInfo.email} className="form-control" handleChange={(v: string) => {
                                const x = {...loginInfo};
                                x.email = v;
                                loginInfoSet(x);
                            }}/>
                        </Form.Group>
                        <Form.Group className="">
                            <Form.Label className="w-100">
                                <span><KeyFill /> Password</span>
                                <span className="d-sm-none"><br/></span>
                                <Link to="/app/forgot" className='float-sm-end'>Forgot Password?</Link>
                            </Form.Label>
                            <InputControlled type="password" autoComplete="curent-password" value={loginInfo.password} className="form-control" handleChange={(v: string) => {
                                const x = {...loginInfo};
                                x.password = v;
                                loginInfoSet(x);
                            }}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <div className='d-flex py-2 flex-column align-items-center'>
                            <ButtonFancy type="submit" disabled={submitting || loginInfo.email === "" || loginInfo.password === ""}>
                                Login
                            </ButtonFancy>
                            <ButtonFancy type="submit" className="mt-3">
                                <a href="/api/v1/authentication/login-steam.php">Login with Steam</a>
                            </ButtonFancy>
                        </div>
                        <div className="d-flex py-2 flex-column align-items-center small">
                            <p className='text-muted mb-0'>You have no Account?</p>
                            <Link to="/app/register" className='text-link'>Register</Link>

                            <p className='text-muted mb-0 mt-3'>Forgot Password?</p>
                            <Link to="/app/forgot" className='text-link'>Reset Password</Link>
                        </div>
                    </Card.Footer>
                </Form>
            </Card>
        </div>);
};