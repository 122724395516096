import {Link, useNavigate, useSearchParams} from "react-router-dom";
import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {ButtonFancy} from "../basic/ButtonFancy";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {analyticsPageViewCurrent} from "../analytics/AnalyticsService";

interface Article {
    title: string;
    created: string;
    writer: string;
    iframeLink: string;
    videoLink?: string;
}

const articleDict: Record<string, Article> = {
    "2024-12-zillilag-tournament": {
        title: "I won a thing (x5) - December Tournament Writeup",
        created: "December 31, 2024",
        writer: "Zillilag",
        iframeLink: "https://docs.google.com/document/d/e/2PACX-1vQrOeN2me-M2Bu5vdFsRWydN-oTugfBgN17a6xBf7jPR1tnpzXXWGP_6BSZjaLXAElqgD41xwjAHwk-/pub?embedded=true",
    },
    "2024-10-ihsb-tournament": {
        title: "October Tournament Writeup",
        created: "November 2, 2024",
        writer: "IHSB15",
        iframeLink: "https://docs.google.com/document/d/e/2PACX-1vRAQ62lTWdEl8vUoJJ74e7jcoE-u_TjAZ8iA-Mo2ZQAh-BMS1uCjzRNEmkQxw3WkWhtKcOrchsVwyK5/pub?embedded=true",
    },

    "2024-07-zillilag-tournament": {
        title: "I won a thing again (again) - July Tournament Writeup",
        created: "July 29, 2024",
        writer: "Zillilag",
        iframeLink: "https://docs.google.com/document/d/e/2PACX-1vTKN8349hrRm1m_WBZdBG6qsY9dHytxn3U6uh-yZQPq6chr7XBC0je2jgaG9ed7aZyalAofRlCqFdkJ/pub?embedded=true",
    },

    "2024-06-zillilag-tournament": {
        title: "I won a thing again - June Tournament Writeup",
        created: "June 24, 2024",
        writer: "Zillilag",
        iframeLink: "https://docs.google.com/document/d/e/2PACX-1vRt7QsXN-DQTqdWMJgsD9_niaawy4xf-8VE3jfhQk4xXvbD_Kf5XWZ2iqUFA-C8zmz6cbx3wWphbSiV/pub?embedded=true",
    },

    "2024-06-elusiveconscript-boost": {
        title: "Fairytale Fables 101 - Boost",
        created: "June 10, 2024",
        writer: "ElusiveConscript",
        iframeLink: "https://docs.google.com/document/d/e/2PACX-1vR1G_HW5a6XGn7h_fEG81LDZ32unnCdEVsoyKEtDm9TTuacowvXeFkkyp1593b85cdc-PUWAUqSb-hd/pub?embedded=true",
    },

    "2024-04-zillilag-tournament": {
        title: "I won a thing - April Tournament Writeup",
        created: "April 29, 2024",
        writer: "Zillilag",
        iframeLink: "https://docs.google.com/document/d/e/2PACX-1vSaec2kZqAjnprX8--nwSO-KqNalHvFCJmajbfke5Q3uwwdHZ1OxfwianTTad2sTj0ImXDkMP-UWamr/pub?embedded=true",
    },

    "2024-04-zillilag-monstermash": {
        title: "The life of a Vampire, Part 1: The Monster Mash",
        created: "April 22, 2024",
        writer: "Zillilag",
        iframeLink: "https://docs.google.com/document/d/e/2PACX-1vTlJgNiIfhJDARrdm0BLTfg1X_Jl6ZJFfvY-DAI6qriIji4eV-3VvT_m739LudKZDfMb16qUNmZnveb/pub?embedded=true",
        videoLink: "https://www.youtube.com/embed/YoVwMRqBYqk?si=IzXLyy7gvDV7n6qK",
    },
};
const articleList: string[] = [];
for (let articleDictKey in articleDict) {
    articleList.push(articleDictKey);
}

export function ArticlesPageWithContainer(props: {maxArticles?:number}) {
    return <div className="position-relative">
        <section className="d-flex align-items-center">
            <div className="container bg-fancy card mt-5" style={{minHeight: "50vh"}}>
                <h2 className="text-center">Articles</h2>
                <hr/>
                <ArticlesPage maxArticles={props.maxArticles}/>
            </div>
        </section>
    </div>;
}

export default function ArticlesPage(props:{maxArticles?:number}) {
    const navigate = useNavigate();
    const [searchParams, searchParamsSet] = useSearchParams();
    const [article, articleSet] = useState<Article>();

    // initial loading of article
    useEffect(() => {
        const articleName = searchParams.get("article");
        if (!articleName) {
            if (article) articleSet(undefined);
            return;
        }
        if (articleName in articleDict) articleSet(articleDict[articleName])
    }, [searchParams, article, articleSet]);

    const backToList = useCallback(() => {
        searchParamsSet({});
        articleSet(undefined);
        analyticsPageViewCurrent();
    }, [searchParamsSet, articleSet]);

    const select = useCallback((articleName: string) => {
        if (!(articleName in articleDict)) return;
        navigate("/articles?article=" + articleName);
        /*searchParamsSet({article: articleName});
        articleSet(articleDict[articleName])*/
    }, [navigate]);

    if (article) {
        return <div>
            <h2 className="text-center">{article.title}</h2>
            <div className="d-flex">
                <ButtonFancy bg="blue" onClick={backToList} className="mb-3 m-auto px-5">Back</ButtonFancy>
            </div>
            <div>
                <ArticleView article={article}/>
            </div>
        </div>;
    }

    const listCopy = props.maxArticles && props.maxArticles>0 ? articleList.slice(0, props.maxArticles) : articleList;
    return <div>
        {listCopy.map(articleName => <ArticleListDisplay key={articleName} articleName={articleName}
                                                            select={select}/>)}
    </div>;
}

function ArticleListDisplay(props: { articleName: string, select: (articleName: string) => void }): ReactElement {
    if (!(props.articleName in articleDict)) return <></>;
    const article = articleDict[props.articleName];

    return <div className="">
        <h4><Link to={"/articles/?article=" + props.articleName} className="d-inline-block mt-2 fw-bold">{article.title}</Link></h4>
        <ButtonFancy className="float-end mt-1 px-5" onClick={() => props.select(props.articleName)}>View</ButtonFancy>
        <h5 className="">By: {article.writer}</h5>
        <h6 className="">{article.created}</h6>
        <hr/>
    </div>;
}

function ArticleView(props: {article: Article}): ReactElement {
    useEffect(() => {
        analyticsPageViewCurrent()
    }, [props]);

    const { viewportWidth} = useWindowDimensions();
    const article = props.article;

    const sizeBig = viewportWidth > 991;
    let width = sizeBig ? 740 : 560;
    if(viewportWidth > 1200) width = 900;
    const height = Math.round(width / (16/9.0));

    return <div>
        <div className="text-center">
            <h5>By: {article.writer}</h5>
            <h6>{article.created}</h6>
        </div>

        {article.videoLink && <div className="d-flex mb-4">
            <iframe className="m-auto" width={width} height={height} src={article.videoLink}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>}

        <div className="d-flex overflow-hidden">
            <iframe title="Article" src={article.iframeLink} className="article-iframe m-auto" width="100%"
                    height="850" style={{maxWidth: "800px"}}></iframe>
        </div>
    </div>;
}